import React from 'react'
import { TimelineShift } from 'api/shift'
import { Flex, Heading, Link, useAppTheme } from 'ui'
import { getShiftTimezone } from 'lib/shift'
import { formatInTimeZone } from 'date-fns-tz'
import { ChevronRight } from 'lucide-react'

type DynamicPageTitleProps = {
  shift: TimelineShift
}

export const DynamicPageTitle = ({ shift }: DynamicPageTitleProps) => {
  const { colors } = useAppTheme()
  const startDate = new Date(shift.startsAt)
  const timezone = getShiftTimezone(shift)

  const day = formatInTimeZone(startDate, timezone, 'EEEE, MMMM d')

  if (shift.assignment?.id) {
    const formattedStartDate = formatInTimeZone(
      startDate,
      timezone,
      'yyyy-MM-dd'
    )
    return (
      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'center']}
        style={{ gap: 4 }}
      >
        <Heading level={2} fontWeight={'normal'}>
          <Link
            to={`/assignments/${shift.assignment.id}?weekStart=${formattedStartDate}`}
          >
            {shift.assignment.name}
          </Link>
        </Heading>

        <ChevronRight size={24} color={colors.neutrals[200]} />

        <Heading level={2} fontWeight={'normal'}>
          {day}
        </Heading>
      </Flex>
    )
  }

  return (
    <Heading level={2} fontWeight={'normal'}>
      {day}
    </Heading>
  )
}
