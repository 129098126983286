import React, { useCallback, useEffect, useState } from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
import { Box, BoxProps } from 'ui'
import { Marker, MarkerProps } from './Marker'
import { config } from 'config'
import { MapCircle } from './MapCircle'

interface Props extends BoxProps {
  zoom: number
  center?: {
    lat: number
    lng: number
  }
  markers?: MarkerProps[]
  circle?: {
    lat: number
    lng: number
    radius: number
    color?: string
    draggable?: boolean
    onCenterChange?: (center: { lat: number; lng: number }) => void
  }
  mapOptions?: google.maps.MapOptions
}

export function Map(props: Props) {
  const {
    center: initialCenter,
    zoom,
    markers,
    circle,
    mapOptions,
    ...boxProps
  } = props
  const [center, setCenter] = useState(initialCenter)
  const [map, setMap] = useState<google.maps.Map | null>(null)

  const ref = useCallback((node) => {
    if (node) {
      const map = new window.google.maps.Map(node, {
        zoom,
        center,
        ...mapOptions,
      })
      setMap(map)
    }
  }, [])

  useEffect(() => {
    if (map && center) {
      map.setCenter(center)
    }
  }, [center, map])

  useEffect(() => {
    if (map && zoom) {
      map.setZoom(zoom)
    }
  }, [zoom, map])

  useEffect(() => {
    // if initial center has changed
    if (initialCenter && center) {
      if (
        initialCenter.lat !== center.lat ||
        initialCenter.lng !== center.lng
      ) {
        setCenter(initialCenter)
      }
    }
  }, [initialCenter])

  return (
    <Wrapper
      apiKey={config.googleMapsApiKey}
      libraries={['geocoding', 'places']}
    >
      <Box borderRadius={'standard'} height={250} {...boxProps} ref={ref} />
      {map && markers
        ? markers.map((marker) =>
            marker.position ? (
              <Marker
                key={`${marker.position.lat}-${marker.position.lng}`}
                map={map}
                {...marker}
              />
            ) : null
          )
        : null}
      {map && circle ? (
        <MapCircle
          map={map}
          radius={circle.radius}
          center={{ lat: circle.lat, lng: circle.lng }}
          onCenterChange={circle.onCenterChange}
          draggable={circle.draggable}
          color={circle.color}
        />
      ) : null}
    </Wrapper>
  )
}
