import { api } from 'api/api'

type ReviewAssignmentCalloutDecisionRequest = {
  companyId: number
  calloutId: number
  status: 'approve' | 'reject'
}

export const reviewAssignmentCalloutDecision = (
  request: ReviewAssignmentCalloutDecisionRequest
) => {
  const { companyId, calloutId, status } = request
  return api.put(`/company/${companyId}/callout/${calloutId}/status/${status}`)
}
