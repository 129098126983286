import React from 'react'
import { Box } from 'ui'
import { Button } from '@workwhile/ui'
import { useIntercom } from 'react-use-intercom'
import { useLayoutContext } from 'pages/layout/useLayoutContext'
import { useNavigate } from 'react-router'
import { HeadphonesIcon, PlusCircleIcon } from 'lucide-react'
import { PermissionEnum } from 'api/userPermissions'
import { AuthGuard } from 'components/auth'

export function CtaButtons() {
  const { show: showIntercom } = useIntercom()
  const { mobileMenuOpen, toggleMobileMenu } = useLayoutContext()
  const navigate = useNavigate()

  return (
    <Box p={4} className={'cta-container'}>
      <AuthGuard.Permission value={PermissionEnum.ManageShifts}>
        <Button
          aria-label={'Create Shift'}
          my={2}
          block={true}
          onClick={() => {
            navigate('/home/shift-editor/new')
          }}
        >
          <PlusCircleIcon size={18} />
          <span className={'cta-text'}>Create Shift</span>
        </Button>
      </AuthGuard.Permission>
      <Button
        aria-label={'Contact Support'}
        my={2}
        variant={'secondary'}
        block={true}
        onClick={() => {
          showIntercom()
          if (mobileMenuOpen) {
            toggleMobileMenu()
          }
        }}
      >
        <HeadphonesIcon size={18} />
        <span className={'cta-text'}>Support</span>
      </Button>
    </Box>
  )
}
