import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import { getShiftTimezone } from 'lib/shift'
import { WorkShift } from 'typings/common_defs'

export const getShiftDate = (
  shift: Pick<WorkShift, 'endsAt' | 'location'>
): string => {
  if (!shift.endsAt) {
    throw new Error('endsAt is required')
  }
  const timezone = getShiftTimezone(shift)
  const formattedDate = formatInTimeZone(shift.endsAt, timezone, 'yyyy-MM-dd')
  return formattedDate
}
