import React from 'react'
import { InvoiceDetail } from 'api/payment'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import logo from '../../assets/ww_logo_blue.png'
import { formatCurrency } from 'lib/string'
import { format } from 'date-fns'

interface Props {
  data: InvoiceDetail
}

export function InvoicePDF(props: Props) {
  const { data } = props
  const {
    chargePayment: item,
    chargeUpfront,
    companyName,
    primaryContact,
  } = data

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header}>
          <Image src={logo} style={styles.logo} cache={false} />
          <View>
            {!chargeUpfront && (
              <Text style={styles.textRight}>Invoice #: {item.id}</Text>
            )}
            {chargeUpfront ? (
              <Text style={styles.textRight}>Receipt #: {item.id}</Text>
            ) : null}
            <Text style={styles.textRight}>
              Created: {format(new Date(item.createdAt), 'MMM do, yyyy')}
            </Text>
            {!chargeUpfront && (
              <Text style={styles.textRight}>
                Due: {format(new Date(item.dueAt), 'MMM do, yyyy')}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.subheader}>
          <View>
            <Text style={styles.textRight}>{companyName}</Text>
            {primaryContact ? (
              <>
                <Text style={styles.textRight}>{primaryContact.name}</Text>
                <Text style={styles.textRight}>{primaryContact.email}</Text>
              </>
            ) : null}
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Description</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Quantity</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Unit Amount</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Amount</Text>
            </View>
          </View>
          {item.chargeItems.map((ci) => (
            <View key={ci.id} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{ci.description}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{ci.quantity}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{ci.unitAmount}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{ci.amount}</Text>
              </View>
            </View>
          ))}
        </View>
        <Text style={styles.textWithMargin}>
          Total: {formatCurrency(item.totalAmount)}
        </Text>
        {!chargeUpfront && (
          <View style={styles.subheaderCol}>
            <View>
              <Text style={styles.text}>Pay with ACH or Wire transfer</Text>
              <Text style={styles.text}>Bank: Silicon Valley Bank</Text>
              <Text style={styles.text}>Routing: 121140399</Text>
              <Text style={styles.text}>Account: 3303874673</Text>
              <Text style={styles.text}>SWIFT: SVBKUS6S</Text>
            </View>
            <View>
              <Text style={styles.text}>Mail checks through USPS to</Text>
              <Text style={styles.text}>Workforce as a Service, Inc</Text>
              <Text style={styles.text}>PO BOX 121909</Text>
              <Text style={styles.text}>Dallas, TX 75312-1909</Text>
            </View>
            <View>
              <Text style={styles.text}>
                Mail checks through FedEx, UPS, and other couriers to
              </Text>
              <Text style={styles.text}>Workforce as a Service, Inc</Text>
              <Text style={styles.text}>891909</Text>
              <Text style={styles.text}>1501 North Plano Rd Ste 100</Text>
              <Text style={styles.text}>Richardson, TX 75081</Text>
            </View>
            <View>
              <Text style={styles.text}>AR@workwhilejobs.com</Text>
              <Text style={styles.text}>
                Questions? Contact WorkWhile at AR@workwhilejobs.com
              </Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  body: {
    padding: 20,
  },
  table: {
    // display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subheader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 20,
  },
  subheaderCol: {
    marginTop: 10,
    flexDirection: 'column',
    gap: 10,
  },
  logo: {
    height: 40,
  },
  text: {
    fontSize: 12,
  },
  textWithMargin: {
    fontSize: 12,
    marginTop: 10,
  },
  textRight: {
    fontSize: 12,
    align: 'right',
  },
})
