import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useAppTheme,
} from '@workwhile/ui'
import { Briefcase, CalendarClock, MessageSquareText } from 'lucide-react'
import { formatInTimeZone } from 'date-fns-tz'
import { getLocationTimezone } from 'lib/time'
import { formatDistanceToNow } from 'date-fns'
import { useAssignmentCalloutsContextValue } from '../context/AssignmentCalloutsProvider'
import { ShiftWithCallouts } from '../types'

type PendingCalloutReviewItemProps = {
  shift: ShiftWithCallouts
}
export const PendingCalloutReviewItem = ({
  shift,
}: PendingCalloutReviewItemProps) => {
  const { colors, radii, space } = useAppTheme()
  const [lastClickedButton, setLastClickedButton] = useState<
    'approve' | 'reject' | null
  >(null)

  const {
    calloutMutationState: { isPending, reviewCalloutDecision },
  } = useAssignmentCalloutsContextValue()

  const { startsAt, endsAt, work, location, position, assignment } = shift
  const [{ worker, callouts }] = work

  const callout = callouts.at(-1)

  // Should never happen, but if it does, we just don't render anything
  // We should improve OpEx here and get notified if this happens
  if (!callout) {
    return null
  }

  const startsAtDate = new Date(startsAt)
  const endsAtDate = new Date(endsAt)

  // Tue Oct 8, 9:00 AM to 7:00 PM CDT (in 12 days)
  const formattedStartsAt = formatInTimeZone(
    startsAtDate,
    getLocationTimezone(location),
    'MMM d, h:mm a'
  )

  const formattedEndsAt = formatInTimeZone(
    endsAtDate,
    getLocationTimezone(location),
    'h:mm a z'
  )

  const relativeFormattedStartsAt = formatDistanceToNow(startsAtDate, {
    addSuffix: true,
  })

  const calloutSubmittedOn = formatInTimeZone(
    new Date(callout.createdAt),
    getLocationTimezone(location),
    'MMM d, h:mm a z'
  )

  const handleClick = (button: 'approve' | 'reject') => {
    setLastClickedButton(button)

    reviewCalloutDecision({
      calloutId: callout.id,
      status: button,
    })
  }

  return (
    <Flex
      border="1px solid"
      borderColor="neutrals.100"
      p={2}
      borderRadius={radii.standard}
      flexDirection="column"
      style={{
        gap: space[3],
      }}
    >
      <Flex flexDirection="column" style={{ gap: space[1] }}>
        <Heading
          level={4}
          as="h3"
          fontWeight="400"
          style={{
            margin: 0,
          }}
        >
          {assignment.name}
        </Heading>
        <Flex
          flexDirection={['column', 'column', 'row']}
          style={{ gap: space[3] }}
        >
          <Flex alignItems="center">
            <Briefcase size={12} color={colors.neutrals[300]} />{' '}
            <Text as="span" ml={1}>
              {position.name}
            </Text>
          </Flex>

          <Flex alignItems="center">
            <CalendarClock size={12} color={colors.neutrals[300]} />
            <Text as="span" ml={1}>
              {formattedStartsAt} to {formattedEndsAt} (
              {relativeFormattedStartsAt})
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'center']}
        justifyContent="space-between"
        style={{
          gap: space[4],
        }}
      >
        <Flex alignItems="center">
          <Avatar
            user={{
              photoUrl: worker.profilePicUrl,
              name: worker.name,
            }}
            size="large"
          />
          <Text as="span" ml={3}>
            {worker.name}
          </Text>
        </Flex>
        <Flex
          flexDirection="column"
          style={{ gap: space[1] }}
          maxWidth={['100%', '100%', '40%']}
          minWidth={['100%', '100%', '40%']}
        >
          <Flex alignItems="center">
            <MessageSquareText size={14} color={colors.lightText} />
            <Text as="span" ml={1} color={colors.lightText}>
              Requested on {calloutSubmittedOn}
            </Text>
          </Flex>
          <Box
            as="span"
            backgroundColor="neutrals.100"
            p={2}
            borderRadius={radii.standard}
          >
            {callout.reason}
          </Box>
        </Flex>
        <Flex
          style={{ gap: space[2] }}
          justifyContent="flex-end"
          width={['100%', '100%', 'auto']}
        >
          <Button
            variant="secondary"
            kind="medium"
            onClick={() => handleClick('reject')}
            loading={lastClickedButton === 'reject' && isPending}
            disabled={isPending}
          >
            Reject
          </Button>
          <Button
            kind="medium"
            onClick={() => handleClick('approve')}
            loading={lastClickedButton === 'approve' && isPending}
            disabled={isPending}
          >
            Approve
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
