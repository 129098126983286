import {
  CalendarViewWorker,
  CalendarViewWorkerFilter,
} from 'pages/AssignmentDetailPage/types'
import { values } from 'ramda'

export const isWorkerUnscheduled = (worker: CalendarViewWorker) => {
  const works = values(worker.works)

  return works.every((work) => {
    return work.every((shift) => {
      return (
        shift.status === 'removed' &&
        shift.callouts?.at(-1)?.status !== 'approved'
      )
    })
  })
}
/**
 * Filters the workers by status
 *
 * "Active" - Workers who have been assigned to the assignment or are part of the assignment
 * "Unscheduled" - Workers who were once part of the assignment but are no longer assigned to the assignment. DOES NOT include workers who requested callout for specific shifts
 * "All" - All workers
 */
export const filterWorkersByAssignmentStatus = (
  workers: CalendarViewWorker[],
  status: CalendarViewWorkerFilter
) => {
  // unscheduled workers are
  if (status === 'active') {
    const activeWorkers = workers.filter((worker) => {
      const isUnscheduled = isWorkerUnscheduled(worker)
      return !isUnscheduled
    })

    return activeWorkers
  }

  if (status === 'unscheduled') {
    return workers.filter((worker) => {
      const isUnscheduled = isWorkerUnscheduled(worker)
      return isUnscheduled
    })
  }

  return workers
}
