import React, { useMemo } from 'react'
import { Map } from 'components/Map'
import { Work } from 'typings/common_defs'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'
import { Box, useAppTheme } from '@workwhile/ui'
import { DemoTrackingInfo } from './DemoTrackingInfo'

export function DemoTrackingMap({ work }: { work: Work }) {
  const { shift } = useShiftDetail()
  const { colors } = useAppTheme()
  const worker = work.worker
  const address = shift?.location?.address
  const hasCoords = address?.lat && address?.long
  const workerMarker = useMemo(() => {
    if (!hasCoords) return undefined
    return {
      position: {
        lat: parseFloat(address?.lat),
        lng: parseFloat(address?.long),
      },
      title: worker?.name,
      icon: {
        path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
        fillColor: 'black',
        fillOpacity: 1,
        scale: 4,
        strokeWeight: 2,
        rotation: 0,
      },
    }
  }, [hasCoords, address, worker])
  const center = useMemo(() => {
    if (!hasCoords) return undefined
    // Offset center by 100m to the right
    // 1 degree longitude is approximately 111km * cos(latitude) at the equator
    // So to move 100m right, we add 100/(111000 * cos(lat)) degrees to longitude
    const lat = parseFloat(address?.lat)
    const lng = parseFloat(address?.long)
    const lngOffset = 100 / (111000 * Math.cos((lat * Math.PI) / 180))
    return {
      lat,
      lng: lng - lngOffset,
    }
  }, [hasCoords, address])

  return (
    <Box position="relative">
      <Map
        zoom={17}
        height={400}
        center={center}
        mapOptions={{
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
        circle={
          hasCoords
            ? {
                lat: parseFloat(address?.lat),
                lng: parseFloat(address?.long),
                radius: 100,
                color: colors.neutrals[300],
                draggable: false,
              }
            : undefined
        }
        markers={workerMarker ? [workerMarker] : []}
      />
      <DemoTrackingInfo work={work} />
    </Box>
  )
}
