import { graphql } from '../graphql'
import { format } from 'date-fns'
import { path } from 'ramda'
import { WorkShift } from 'typings/common_defs'

export enum LogEventType {
  ShiftCreate = 'shift_create',
  ShiftModify = 'shift_modify',
  E2WReview = 'e2w_review',
  CompanyWorkerProfile = 'company_worker_profile',
  Work = 'work',
  UserPayment = 'user_payment',
  CompanyIssuedBonus = 'company_issued_bonus',
  RequestCallout = 'request_callout',
  ApproveCallout = 'approve_callout',
  RejectCallout = 'reject_callout',
}

export interface CompanyLog {
  timestamp: string
  message: string
  userId: number
  companyId: number
  shiftId: number
  subjectUserId: number
  eventType: LogEventType
  user?: {
    id: number
    name: string
  }
  shift?: WorkShift
  subjectUser?: {
    id: number
    name: string
  }
}

const logDateFormat = "yyyyMMdd'T'HH:mm:ssx"
export async function getCompanyLogs(options: {
  startDate: Date
  endDate: Date
}) {
  const { startDate, endDate } = options
  const data = await graphql(`
    company { 
      eventLogs (
          ${startDate ? `startsAt: "${format(startDate, logDateFormat)}"` : ''}
          ${endDate ? `endsAt: "${format(endDate, logDateFormat)}"` : ''}
      ) {
          timestamp, message, userId, companyId, shiftId, subjectUserId, eventType,
          user {
              id, name
          },
          shift {
              id, startsAt, endsAt, workersNeeded
              position { id, name }, locationless, location { id, name, address {street, unit, city, state, zip, timezone}},
              supervisor, supervisorPhone, supervisors { id, name, phoneNumber, userId, status},
          },
          subjectUser {
              id, name
          }
      }
    }
  `)

  return path(['data', 'data', 'company', 'eventLogs'], data) as CompanyLog[]
}
