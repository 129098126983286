import React from 'react'
import { AssignmentCalloutsProvider } from './context/AssignmentCalloutsProvider'
import { AssignmentCalloutsContainer } from './AssignmentCalloutsContainer'
import { useShouldEnableLTARequestCallouts } from 'hooks/useShouldEnableLTARequestCallouts'

export const AssignmentCallouts = () => {
  const shouldEnableAssignmentCallouts = useShouldEnableLTARequestCallouts()

  if (shouldEnableAssignmentCallouts) {
    return (
      <AssignmentCalloutsProvider>
        <AssignmentCalloutsContainer />
      </AssignmentCalloutsProvider>
    )
  }

  return null
}
