import React from 'react'
import { Flex, useAppTheme } from '@workwhile/ui'
import { PendingCalloutReviewItem } from './PendingCalloutReviewItem'
import { useAssignmentCalloutsContextValue } from '../context/AssignmentCalloutsProvider'

export const PendingCalloutReviews = () => {
  const { space } = useAppTheme()
  const {
    calloutsQueryState: { data },
  } = useAssignmentCalloutsContextValue()

  const pendingCalloutReviewItems = data.map((shift) => {
    const uniqueRowId = `${shift.id}-${shift.work[0]?.callouts.at(-1)?.id}`
    return <PendingCalloutReviewItem key={uniqueRowId} shift={shift} />
  })

  return (
    <Flex flexDirection="column" style={{ gap: space[3] }}>
      {pendingCalloutReviewItems}
    </Flex>
  )
}
