export const reviewRootKey = ['review']
export const reviewOvertimeApprovalKey = [
  ...reviewRootKey,
  'reviewOvertimeApproval',
]
export const reviewTryoutsKey = [...reviewRootKey, 'reviewTryouts']
export const reviewEvaluateKey = [...reviewRootKey, 'reviewEvaluate']
export const reviewAssignmentsWithRequestedCalloutsKey = [
  ...reviewRootKey,
  'reviewAssignmentsWithRequestedCallouts',
]
