import React from 'react'
import { Work } from 'typings/common_defs'
import { Box } from '@workwhile/ui'
import { DemoTrackingInfo } from './DemoTrackingInfo'
import { Wrapper } from '@googlemaps/react-wrapper'
import { config } from 'config/index'
import { DemoTrackingMap } from './DemoTrackingMap'

export function DemoTrackingView({ work }: { work: Work }) {
  return (
    <Box position="relative">
      <Wrapper
        apiKey={config.googleMapsApiKey}
        libraries={['geocoding', 'places']}
      >
        <DemoTrackingMap work={work} />
      </Wrapper>
      <DemoTrackingInfo work={work} />
    </Box>
  )
}
