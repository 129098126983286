import { useEffect } from 'react'

export type MarkerProps = google.maps.MarkerOptions

export function Marker(props: MarkerProps) {
  useEffect(() => {
    const marker = new google.maps.Marker(props)

    return () => {
      marker.setMap(null)
    }
  }, [])

  return null
}
