import { Trip, Work } from '../../typings/common_defs'
import { determineIfWorkerUnableToAttendShift } from '../shift'
import { getRosterWorkersOnShift } from '../roster'
import { TimelineShift } from 'api/shift'
import { differenceInMinutes } from 'date-fns'
import { getLongDuration } from '../time'
import { AssignedWorkerOptionsEnum } from './assigned_work_options'

export const SHIFT_DETAIL_WORKER_ACTIONS = {
  viewLocation: 'viewLocation',
  message: 'message',
  adjustTime: 'adjustTime',
}

export type ShiftDetailWorkerAction =
  (typeof SHIFT_DETAIL_WORKER_ACTIONS)[keyof typeof SHIFT_DETAIL_WORKER_ACTIONS]

const WORK_EDITABLE_STATUSES = [
  'scheduled',
  'started',
  'completed',
  'employer_review',
  'admin_review',
]

export const WILL_OR_HAS_WORKED_STATUSES = [
  'completed',
  'scheduled',
  'started',
  'approved',
  'paid',
  'employer_approved',
  'admin_review',
  'employer_review',
]

export const APPROVED_WORK_STATUSES = ['employer_approved', 'approved', 'paid']

export const FINISHED_WORK_STATUS = [
  'completed',
  'admin_review',
  'employer_review',
  'employer_approved',
  'approved',
  'paid',
  'bailed',
]

export const UNABLE_TO_ATTEND_SHIFT_REQUEST_STATUSES = [
  'worker_declined_req',
  'system_declined_req',
  'rejected',
  'removed',
]

// TODO(Alex-RSW): deprecate this function
// - offered and reserved statuses will no longer exist once RSW V2 is in prod
export const getNumWorkAssigned = (work: Array<Work>) => {
  let numOffered = 0
  for (let i = 0; i < work.length; i += 1) {
    const w = work[i]
    if (w.status === 'offered' || w.status === 'reserved') numOffered += 1
  }
  return work.length - numOffered
}

/**
 * Given a list of work, return the work where the worker is assigned
 * There is no filtering on frontend - handled on backend
 * @param {Array<Work>} work
 * @returns {Array<Work>}
 */
export const getAssignedWork = (work: Array<Work>): Array<Work> => work

export const isWorkEditable = (work: Work): boolean => {
  if (!work || !work.status) {
    return false
  }

  if (WORK_EDITABLE_STATUSES.includes(work.status)) {
    return true
  }

  return false
}

/**
 * Return true if the work has at least one assigned worker
 */
export const containsAssignedWorker = (work) => {
  for (let i = 0; i < work.length; i += 1) {
    const worker = work[i]
    if (worker.status !== 'offered') {
      return true
    }
  }
  return false
}

/**
 * Given a Work, returns all trips with a valid URL (shareUrl) the biz portal user can access
 * @param {Work} work
 * @returns {Array<Trip>}
 */
export const getTripsWithShareUrl = (work: Work): Array<Trip> => {
  const trips = work.trips || []
  return trips.filter((trip) => trip.shareUrl)
}

/**
 * Determine the variant of status badge to show for a given work
 * @param {TimelineShift} shift
 * @param {Work} work
 * @returns {"pending" | "accepted" | "unavailable" | null}
 */
export const getWorkStatusBadgeVariant = (
  shift: TimelineShift,
  work: Work
): 'pending' | 'accepted' | 'unavailable' | null => {
  // if the worker is;
  // on a roster associated with the shift, show the badge
  // - if the worker assigned to the shift, show the Accepted badge
  // - if the worker is unavailable for the shift, show the Unavailable badge
  // - else show the Pending badge
  const worker = work.worker
  if (!worker || !worker.id) return null
  const rosterWorkers = getRosterWorkersOnShift(shift)
  const rosterWorkerIds = rosterWorkers.map((worker) => worker.id)
  if (rosterWorkerIds.includes(worker.id)) {
    const workForWorker = shift.work
      ? getAssignedWork(shift.work).filter(
          (work) => work.worker && work.worker.id === worker.id
        )
      : []
    if (workForWorker.length > 0) {
      return 'accepted'
    }
    if (determineIfWorkerUnableToAttendShift(worker, shift)) {
      return 'unavailable'
    }
    return 'pending'
  }

  // if we should not show the badge, return null
  return null
}

/**
 * Determine if we should show the "Approve" button on a given Work item
 * - should return true if the work is finished and hasn't been approved
 * - or if the biz portal user has entered both a startedAt and completedAt value
 * @param {Work} work - Work item
 * @returns {boolean} Whether the "Approve" button should be shown
 */
export const shouldShowApproveButton = (work: Work): boolean => {
  // check that the work is in an "approvable status"
  // NOTE: we don't yet support 'un-bailing' a worker via the biz portal
  const disallowedStatuses = ['employer_approved', 'approved', 'paid', 'bailed']
  if (!work.status || disallowedStatuses.includes(work.status)) return false

  // if the work is to be marked No Show, the work can be approved (no further timestamp or status checks required)
  if (work.finishedEarlyCategory === AssignedWorkerOptionsEnum.NO_SHOW)
    return true

  // If the biz portal user wants the worker to be paid:
  // - At minimum, the work should have both a startedAt and completedAt value
  // - and the work should be in either a finished_work, started or scheduled status
  //
  // Why do we include scheduled/started status?
  // - Imagine the case where the biz portal user is manually clocking the worker in and out - the only case where'd that happen is if the work is in scheduled/started
  if (
    !!work.startedAt &&
    !!work.completedAt &&
    ['scheduled', 'started', ...FINISHED_WORK_STATUS].includes(work.status)
  )
    return true

  // otherwise return false by default
  return false
}

/**
 * Calculate estimated time worked in minutes.
 * @param {Work} work - Work object with start and completion timestamps.
 * @returns {number} - Time worked in minutes.
 */
export const calculateEstimatedTimeWorkedInMinutes = (work: Pick<Work, 'startedAt' | 'completedAt'>): number => {
  if (!work?.startedAt || !work?.completedAt) return 0
  const startedAt = new Date(work.startedAt)
  const completedAt = new Date(work.completedAt)
  return differenceInMinutes(completedAt, startedAt)
}

/**
 * Calculate estimated time worked in hours and minutes.
 * @param {Work} work - Work object with start and completion timestamps.
 * @returns {string} - Time worked in "Xh Ym" format.
 */
export const calculateEstimatedTimeWorked = (work: Pick<Work, 'startedAt' | 'completedAt'>): string => {
  const totalMinutes = calculateEstimatedTimeWorkedInMinutes(work)
  return getLongDuration(totalMinutes)
}

/**
 * Returns true if we should show the "Needs Attention" label on the work line item
 * @param {Work} work
 * @returns {boolean}
 */
export const shouldShowNeedsAttentionLabel = (work: Work): boolean => {
  // NOTE: Later we'll change this logic to include more cases where work needs attention
  if (work.status === 'employer_review') {
    return true
  }
  return false
}

/**
 * Returns True if the worker worked overtime on the shift
 * @param {Work} work
 * @param {TimelineShift} shift
 * @returns {boolean}
 */
export const didWorkerWorkOvertime = (
  work: Work,
  shift: TimelineShift
): boolean => {
  // worker worked overtime if the worker worked for more than the shift duration
  const shiftStartDate = new Date(shift.startsAt)
  const shiftEndDate = new Date(shift.endsAt)
  const shiftDurationHours =
    differenceInMinutes(shiftEndDate, shiftStartDate) / 60
  const workDurationHours = calculateEstimatedTimeWorkedInMinutes(work) / 60
  return workDurationHours > shiftDurationHours
}

/**
 * Returns True if the worker worked less than the shift duration
 * @param {Work} work
 * @param {TimelineShift} shift
 * @returns {boolean}
 */
export const didWorkerWorkUndertime = (
  work: Work,
  shift: TimelineShift
): boolean => {
  // worker worked Undertime if the worker worked for less than the shift duration
  // (with a 5 min buffer)
  const shiftStartDate = new Date(shift.startsAt)
  const shiftEndDate = new Date(shift.endsAt)
  const shiftDurationHours =
    differenceInMinutes(shiftEndDate, shiftStartDate) / 60
  const workDurationHours = calculateEstimatedTimeWorkedInMinutes(work) / 60
  return workDurationHours < shiftDurationHours
}

/**
 * Returns true if the work is unassigned
 * @param work - Work object
 * @returns {boolean}
 */
export const isWorkRowUnassigned = (work: Work): boolean => {
  if (!work.worker) return true
  if (work.worker && work.worker?.name === 'skeleton') return true
  return false
}
