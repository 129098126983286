import { ascend, path, prop, sort } from 'ramda'
import { ShiftWithCallouts } from 'pages/ReviewPage/AssignmentCallouts/types'
import { graphql } from 'api/graphql'

type GetShiftsWithCalloutsResponseDTO = {
  data: {
    company: {
      shifts: ShiftWithCallouts[]
    }
  }
}

export const getAssignmentsWithRequestedCallouts = async (): Promise<
  ShiftWithCallouts[]
> => {
  const data = await graphql<GetShiftsWithCalloutsResponseDTO>(
    `company {
      shifts(status: "pending_callout_approval") {
        id
        startsAt
        endsAt
        assignment {
          id
          name
        }
        location {
          id
          name
          address {
            timezone
          }
        }
        position {
          id
          name
        }
        work {
          worker {
            id
            name
            profilePicUrl
          }
          callouts {
            id
            reason
            status
            createdAt
          }
        }
      }
    }`,
    'shifts_with_callouts'
  )

  const shifts = path(['data', 'data', 'company', 'shifts'], data)

  // 2D array of shifts with the worker's callouts
  const groupedShiftsByWorkers = shifts.map((shift) => {
    return shift.work.map((work) => ({
      ...shift,
      work: [work],
    }))
  })

  const flattenedShifts = groupedShiftsByWorkers.flat()

  const sortedShifts = sort(ascend(prop('startsAt')), flattenedShifts)

  return sortedShifts
}
