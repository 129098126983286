import { FeatureGates } from 'lib/statsig/feature_gates'
import { useUserProfile } from 'queries/user'
import { useGate } from 'statsig-react'

export const useShouldEnableLTARequestCallouts = () => {
  const { value: shouldEnableLTAEmployerRequestCallouts } = useGate(
    FeatureGates.EnableLTAEmployerRequestCallouts
  )

  // We preemptively call useUserProfile and cache the data in `AssignmentDetailProvider` so that this loads faster
  // and so that we don't have to worry about `loading` state
  // This is temporary until the callouts is fully rolled out to all users
  const { data: userProfile, isSuccess } = useUserProfile()

  if (!isSuccess || !userProfile) {
    return false
  }

  // Always show callouts to the internal users
  if (shouldEnableLTAEmployerRequestCallouts || userProfile.isInternal) {
    return true
  }

  return false
}
