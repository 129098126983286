import { getAssignmentsWithRequestedCallouts } from 'api/review/getAssignmentsWithRequestedCallouts'
import { reviewAssignmentsWithRequestedCalloutsKey } from './keys'
import { useQuery } from '@tanstack/react-query'

export const useAssignmentsWithRequestedCalloutsQuery = () => {
  return useQuery({
    queryKey: reviewAssignmentsWithRequestedCalloutsKey,
    queryFn: getAssignmentsWithRequestedCallouts,
  })
}
