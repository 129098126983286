import { openIntercom } from 'lib/legacy_util'
import { getTripsWithShareUrl } from 'lib/work'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Trip, Work } from 'typings/common_defs'
import { Box, Loading, Modal, Text, Button, Flex, BaseLink } from 'ui'
import { track } from '../../../../lib/amplitude'
import { useIsDemoMode } from 'hooks/useIsDemoMode'
import { DemoTrackingView } from './DemoTrackingView'

interface Props {
  work: Work
  open: boolean
  onClose: () => void
}

export const LocationTrackingMapModal = ({ work, open, onClose }: Props) => {
  const demoMode = useIsDemoMode()
  const trips: Array<Trip> = getTripsWithShareUrl(work)
  const primaryTrip = trips[0]
  const secondaryTrips = trips.slice(1)
  const [iframeLoading, setIframeLoading] = useState(true)

  useEffect(() => {
    // componentDidMount
    track(`impression, location_tracking_map`)
  }, [])

  const hideSpinner = () => {
    setIframeLoading(false)
  }

  if (!trips || trips.length === 0) {
    return null
  }

  return (
    <Modal open={open} width={[1, 900]} onClose={onClose}>
      <Box mb={3}>
        {demoMode ? (
          <DemoTrackingView work={work} />
        ) : (
          <IFrameWrapper>
            {iframeLoading ? <Loading /> : null}
            <iframe
              width="100%"
              height="100%"
              frameBorder="0"
              onLoad={hideSpinner}
              src={`${
                primaryTrip?.shareUrl
              }?start_stage_text=${encodeURIComponent(
                'Shift Started'
              )}&complete_stage_text=${encodeURIComponent(
                'Shift Completed'
              )}&enroute_stage_text=${encodeURIComponent(
                'En route to the Shift'
              )}`}
            />
          </IFrameWrapper>
        )}
      </Box>

      {secondaryTrips && secondaryTrips.length > 0 ? (
        <Box mb={3}>
          <Text fontWeight="bold">
            We have also tracked this worker on the following device(s) during
            this shift:
          </Text>
          <Flex flexDirection="column" alignItems="flex-start">
            {secondaryTrips.map((trip, idx) => (
              <Button
                kind="small"
                variant="text"
                onClick={() => window.open(trip.shareUrl, '_blank')}
                key={`${trip?.serviceDeviceId}-${idx}`}
              >
                {trip.serviceDeviceId}
              </Button>
            ))}
          </Flex>
        </Box>
      ) : null}

      <Box mb={3}>
        <Text fontWeight="bold">Tracking Policy</Text>
        <Text>
          WorkWhile starts tracking workers 90 minutes before shift start time.
          We stop tracking when the worker clocks out.
        </Text>
      </Box>

      <Box mb={3}>
        <Text fontWeight="bold">Why can't I see this worker's location?</Text>
        <Text>
          {' '}
          We occasionally encounter situations where we are unable to track a
          worker. Examples of these include poor internet connection and low
          phone battery. Please{' '}
          <BaseLink onClick={() => openIntercom()}>contact support</BaseLink> if
          you require assistance.
        </Text>
      </Box>
    </Modal>
  )
}

const IFrameWrapper = styled.div`
  ${({ theme: { colors } }) => `
    display: block;
    overflow: hidden;
    width: 100%;
    height: 400px;
    border-radius: 4px;
    transform: translateZ(0px);
    border: 1px solid #eee;
    background-color: ${colors.neutrals[100]};
  `}
`
