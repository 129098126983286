import React, { useContext, createContext, PropsWithChildren } from 'react'
import { useAssignmentDetailQuery } from 'queries/assignment/useAssignmentDetailQuery'
import { Loading, Message } from 'ui'
import { Assignment } from 'typings/common_defs'
import { useUserProfile } from 'queries/user'

export type AssignmentDetailContextState = {
  assignment: Assignment
}

export const AssignmentDetailContext =
  createContext<AssignmentDetailContextState | null>(null)

export const useAssignmentDetailContextStateValue = () => {
  const context = useContext(AssignmentDetailContext)
  if (!context) {
    throw new Error(
      'useAssignmentDetailContextStateValue must be used within a <AssignmentDetailProvider />'
    )
  }
  return context
}

type AssignmentDetailProviderProps = PropsWithChildren<{
  assignmentId?: number
}>

export const AssignmentDetailProvider = (
  props: AssignmentDetailProviderProps
) => {
  const { children, assignmentId } = props

  const {
    data: assignment,
    isLoading,
    isError,
  } = useAssignmentDetailQuery({
    assignmentId,
  })

  // We need to wait for the user profile to load because we need to check if the user is an internal user to enable the review callouts
  // Not doing so will cause a flash of render with invalid state and quickly rehydrate the view with the correct state
  // We use the cache generated by the call below in `useShouldEnableLTARequestCallouts` hook
  const { isLoading: isLoadingUserProfile, isError: isErrorUserProfile } =
    useUserProfile()

  if (isLoading || isLoadingUserProfile) {
    return <Loading />
  }

  if (isError || !assignment || isErrorUserProfile) {
    return (
      <Message variant="error" mt={5}>
        Hmm... we couldn't find this assignment. If you require assistance,
        please copy the URL in your browser's search bar and send it to
        WorkWhile Support.
      </Message>
    )
  }

  const value: AssignmentDetailContextState = {
    assignment,
  }

  return (
    <AssignmentDetailContext.Provider value={value}>
      {children}
    </AssignmentDetailContext.Provider>
  )
}
