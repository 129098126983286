import React, { useEffect } from 'react'
import { Flex, Box, Heading, Message, Text } from 'ui'
import { track } from 'lib/amplitude'
import { Footer } from '../../Footer'
import { useShiftEditor } from '../../useShiftEditor'
import { StepEnum } from '../../ShiftEditorProvider'
import { ReviewCallouts } from './ReviewCallouts'
import { SummaryBox } from './SummaryBox'
import { Preview } from '../../Preview'
import { useShiftSummary } from 'pages/ShiftEditorPage/useShiftSummary'

export function AssignmentConfirmPage() {
  useEffect(() => {
    track('impression, ShiftEditor_AssignmentReview_page')
  }, [])

  const { goTo, editorError, submitShiftData } = useShiftEditor()
  const summary = useShiftSummary()

  const assignmentSummaryLineItems = [
    {
      title: 'Position',
      value: summary.position,
      cta: () => goTo(StepEnum.Details),
    },
    {
      title: 'Location',
      value: summary.location,
      cta: () => goTo(StepEnum.Details),
    },

    {
      title: 'Supervisors',
      value: summary.supervisors,
      cta: () => goTo(StepEnum.Staff),
    },
    {
      title: 'Workers',
      value: summary.workers,
      cta: () => goTo(StepEnum.Staff),
    },
    {
      title: 'Pay',
      value: summary.pay,
      cta: () => goTo(StepEnum.Payment),
    },
    {
      title: 'Bonus',
      value: summary.bonus,
      cta: () => goTo(StepEnum.Payment),
    },
  ]

  const orientationShiftLineItems = summary.orientationShift
    ? [
        {
          title: 'Time',
          value: summary.orientationShift.time,
          cta: () => goTo(StepEnum.OrientationShift),
        },
        {
          title: 'Unpaid Break',
          value: summary.orientationShift.unpaidBreak,
          cta: () => goTo(StepEnum.OrientationShift),
        },
        {
          title: 'Dates',
          value:
            summary.orientationShift.formattedDates.length > 1 ? (
              <>
                <Box flex={1} mt={2}>
                  Dates
                  <Box py={3}>
                    {summary.orientationShift.formattedDates.map(
                      (date, idx) => (
                        <Text key={idx}>{date}</Text>
                      )
                    )}
                  </Box>
                </Box>
                <Box fontWeight={2}>
                  {summary.orientationShift.dates.length} days
                </Box>
              </>
            ) : (
              summary.orientationShift.formattedDates[0]
            ),
          cta: () => goTo(StepEnum.OrientationShift),
        },
      ]
    : []

  const workShiftLineItems = [
    {
      title: 'Time',
      value: summary.time,
      cta: () => goTo(StepEnum.WorkShift),
    },
    {
      title: 'Unpaid Break',
      value: summary.unpaidBreak,
      cta: () => goTo(StepEnum.WorkShift),
    },
    {
      title: 'Dates',
      value:
        summary.formattedDates.length > 1 ? (
          <>
            <Box flex={1} mt={2}>
              Dates
              <Box py={3}>
                {summary.formattedDates.map((date, idx) => (
                  <Text key={idx}>{date}</Text>
                ))}
              </Box>
            </Box>
            <Box fontWeight={2}>{summary.dates.length} days</Box>
          </>
        ) : (
          summary.formattedDates[0]
        ),
      cta: () => goTo(StepEnum.WorkShift),
    },
  ]

  const shiftLeadLineItems = [
    {
      title: 'Shift Leads',
      value: summary.shiftLeads,
      cta: () => goTo(StepEnum.Staff),
    },
    {
      title: 'Time',
      value: summary.shiftLeadTime,
      cta: () => goTo(StepEnum.Staff),
    },
    {
      title: 'Pay',
      value: summary.shiftLeadPay,
      cta: () => goTo(StepEnum.Payment),
    },
  ]

  return (
    <Flex flexWrap={'wrap'}>
      <Box width={[1, 1, 1, '50rem', '65rem']} pr={[0, 2, 5]}>
        <form
          onSubmit={(e) => {
            e.stopPropagation()
            e.preventDefault()
            submitShiftData()
          }}
        >
          <Heading level={[2, 1]} mt={[4, 5]} mb={0}>
            Review & Confirm Your Assignment
          </Heading>
          <Text color="lightText">
            Please make sure all the information is correct.
          </Text>
          <Box>
            <Heading level={3}>Assignment Summary</Heading>
            <SummaryBox lineItems={assignmentSummaryLineItems} />
            {summary.shiftLeads > 0 ? (
              <>
                <Heading level={4}>Shift Leads</Heading>
                <SummaryBox lineItems={shiftLeadLineItems} />
              </>
            ) : null}
            <br />
            <Heading level={3}>Orientation Shift</Heading>
            {summary.orientationShift ? (
              <SummaryBox lineItems={orientationShiftLineItems} />
            ) : (
              <Text color="lightText">No Orientation Shift Selected</Text>
            )}
            <br />
            <Heading level={3}>Work Shift</Heading>
            <SummaryBox lineItems={workShiftLineItems} />
            <ReviewCallouts />
            {editorError ? (
              <Message mt={4} variant={'error'}>
                {editorError}
              </Message>
            ) : null}
            <Footer ctaText={'Save & Confirm'} />
          </Box>
        </form>
      </Box>
      <Box
        display={['none', 'none', 'none', 'inline-flex']}
        pt={[0, 7]}
        justifyContent={'center'}
        width={[1, 1, 1, 1, 'auto']}
        flex={['auto', 'auto', 'auto', 1]}
      >
        <Preview />
      </Box>
    </Flex>
  )
}
