import React from 'react'
import { useShiftDetail } from './useShiftDetail'
import { ShiftDetailInfo } from './ShiftDetailInfo'
import { ShiftDetailWorkers } from './ShiftDetailWorkers'
import { Loading, Message, Box, Flex } from 'ui'
import { shouldShowClockInOutCodes } from 'lib/shift'
import { getAssignedWork, FINISHED_WORK_STATUS } from 'lib/work'
import { ShiftDetailClockInOutCode } from './callouts/ShiftDetailClockInOutCode'
import { ShiftDetailPaymentSummary } from './callouts/ShiftDetailPaymentSummary'
import { ShiftDetailAudience } from './audience'
import TryoutsDayXofY from '../../components/tryouts/TryoutsDayXofY'
import { TryoutLabel } from 'components/tryouts/TryoutLabel'
import { ShiftDetailLeads } from './leads'
import { BackupShiftLabel } from 'components/PostBackupShift/BackupShiftLabel'
import { DynamicPageTitle } from './DynamicPageTitle'

export const ShiftDetailContent = () => {
  const { shift, isLoading } = useShiftDetail()

  if ((!shift || Object.keys(shift).length === 0) && isLoading) {
    return <Loading />
  }
  if (!shift || Object.keys(shift).length === 0) {
    return (
      <Message variant="error">
        Hmm... we couldn't find this shift. If you require assistance, please
        copy the URL in your browser's search bar and send it to WorkWhile
        Support.
      </Message>
    )
  }

  if (!shift) return null

  const getShiftCalloutContent = () => {
    const workList = shift.work || []
    const assignedWork = getAssignedWork(workList)
    // if the shift has ended and all the workers are in a finished status, show the payment summary
    const shiftEndTime = shift.endsAt ? new Date(shift.endsAt) : null
    const isAfterShiftEndTime = shiftEndTime ? shiftEndTime < new Date() : false
    if (
      isAfterShiftEndTime &&
      assignedWork.every(
        (work) => work.status && FINISHED_WORK_STATUS.includes(work.status)
      )
    ) {
      return (
        <Flex flexDirection="column" flex={1} width="100%" height="100%">
          <ShiftDetailPaymentSummary />
        </Flex>
      )
    }

    if (shouldShowClockInOutCodes(shift)) {
      return <ShiftDetailClockInOutCode />
    }

    return null
  }

  const calloutContent = getShiftCalloutContent()

  return (
    <Box>
      <DynamicPageTitle shift={shift} />
      <Box mb={4}>
        {shift.isTryout ? (
          <Flex flexDirection={'row'} alignItems={'center'}>
            <Box mr={3}>
              <TryoutLabel />
            </Box>
            <TryoutsDayXofY shift={shift} />
          </Flex>
        ) : null}
        {shift.shiftToBackup ? (
          <Flex flexDirection={'row'} alignItems={'center'} mr={3}>
            <BackupShiftLabel shift={shift} />
          </Flex>
        ) : null}
      </Box>
      <Flex flexDirection={'row'} flexWrap={'wrap'} flex={1} mb={4}>
        {/* Adjust the width based on if callout is shown */}
        <Box
          width={[1, 1, 1, calloutContent ? 2.8 / 5 : 1]}
          mr={calloutContent ? 6 : 0}
        >
          <Box mb={2}>
            <ShiftDetailInfo />
          </Box>
        </Box>
        {calloutContent ? (
          <Box width={[1, 1, 1, 1.8 / 5]}>{calloutContent}</Box>
        ) : null}
      </Flex>
      <Box>
        <ShiftDetailLeads />
        <ShiftDetailAudience />
        <ShiftDetailWorkers />
      </Box>
    </Box>
  )
}
