import { api, apiDateFormat, graphql } from 'api'
import { path } from 'ramda'
import { format } from 'date-fns'

export type InvoiceType = 'outstanding' | 'paid'
export type InvoiceStatus = 'pending' | 'paid' | 'created'

export interface InvoicesRequest {
  type: InvoiceType
  startDate?: Date
  endDate?: Date
}

export interface ChargeItem {
  id: number
  description: string
  quantity: number
  unitAmount: number
  amount: number
}

export interface ChargePayment {
  id: number
  totalAmount: number
  status: InvoiceStatus
  chargeDetailsUrl: string
  finalizedAt: string
  chargeItems: ChargeItem[]
  createdAt: string
  dueAt: string
  automated: boolean
}

export interface InvoicesResponse {
  chargeUpfront?: boolean
  chargePayments: ChargePayment[]
}

export async function getInvoices(options: InvoicesRequest) {
  const startDate = options.startDate
    ? format(options.startDate, apiDateFormat)
    : ''
  const endDate = options.endDate ? format(options.endDate, apiDateFormat) : ''
  const response = await graphql(
    `company {
          id, name, logoUrl,
          chargeUpfront
          chargePayments(grouping: "${options.type}", startDate: "${startDate}", endDate: "${endDate}") {
            id, totalAmount, status, chargeDetailsUrl, finalizedAt
            chargeItems {
                id
                description
                quantity
                unitAmount
                amount
            }
            createdAt, dueAt
            automated
          }
        }`,
    'receipts'
  )
  return path(
    ['data', 'data', 'company', 'chargePayments'],
    response
  ) as ChargePayment[]
}

interface InvoiceCSVDataOptions {
  type: 'summary' | 'details'
  startDate: Date
  endDate: Date
}

export async function getInvoiceCSVData(
  companyId: number,
  options: InvoiceCSVDataOptions
) {
  const { type, startDate, endDate } = options

  const data = await api.get(
    `/company/${companyId}/${
      type === 'details' ? 'paid_charge_details' : 'paid_charge_summary'
    }`,
    {
      params: {
        start_date: format(startDate, apiDateFormat),
        end_date: format(endDate, apiDateFormat),
      },
    }
  )

  return path(['data'], data)
}

export interface InvoiceDetail {
  companyName: string
  chargeUpfront?: boolean
  primaryContact: {
    name: string
    email: string
  }
  chargePayment: ChargePayment
}

export async function getInvoice(invoiceId: number) {
  const data = await graphql(
    `
      company {
        name,
        chargeUpfront,
        admins {
          name,
          email
        },
        chargePayment(id: ${invoiceId}) {
          id, totalAmount, status, chargeDetailsUrl, finalizedAt
          chargeItems {
              id
              description
              quantity
              unitAmount
              amount
          }
          createdAt, dueAt
          automated
        }
      }
        `,
    'receipt'
  )

  const detail = path(['data', 'data', 'company'], data)

  return {
    companyName: detail.name,
    chargeUpfront: detail.chargeUpfront,
    primaryContact: path(['admins', 0], detail),
    chargePayment: detail.chargePayment,
  } as InvoiceDetail
}

export async function getInvoiceDetailCSVData(url: string) {
  const data = await api.get(url)
  return path(['data'], data)
}
