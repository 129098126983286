import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useAssignmentDetailContextStateValue } from 'pages/AssignmentDetailPage/context'
import { useCalendarEventsQuery } from 'queries/assignment/useCalendarEventsQuery'
import { addDays, format, startOfWeek, subDays } from 'date-fns'
import { useWeekStartState } from './useWeekStartState'
import {
  CalendarViewShift,
  CalendarViewWorker,
  CalendarViewWorkerFilter,
} from 'pages/AssignmentDetailPage/types'
import { filterWorkersByAssignmentStatus } from '../utils/filterWorkersByAssignmentStatus'

export type CalendarViewContextState = {
  actions: {
    goToNextWeek: () => void
    goToPreviousWeek: () => void
    goToToday: () => void
    setWorkerFilter: (filter: CalendarViewWorkerFilter) => void
  }
  state: {
    workerFilter: CalendarViewWorkerFilter
    currentWeekStart: Date
    daysOfWeek: Date[]
    shiftsByDate: {
      date: Date
      shifts: CalendarViewShift[] | undefined
    }[]
    workers: CalendarViewWorker[]
    queryStatus: {
      isSuccess: boolean
      isLoading: boolean
      isError: boolean
    }
  }
}

export const CalendarViewContext =
  createContext<CalendarViewContextState | null>(null)

export const useCalendarViewContextStateValue = () => {
  const context = useContext(CalendarViewContext)
  if (!context) {
    throw new Error(
      'useCalendarViewContextStateValue must be used within a <CalendarViewProvider />'
    )
  }
  return context
}

type CalendarViewProviderProps = PropsWithChildren

/**
 * Provides the state for the calendar view such as the current week start, days of the week, shifts by date, etc.
 *
 * Does not provide the state for any assignment related workflows such as the request workers, etc.
 *
 * The goal is to use this provider for the calendar view and then have a separate provider for the assignment workflows.
 */
export const CalendarViewProvider = ({
  children,
}: CalendarViewProviderProps) => {
  const [currentWeekStart, setCurrentWeekStart] = useWeekStartState()
  // TODO: Once we have success with the wording, we can append this to the URL
  const [workerFilter, setWorkerFilter] =
    useState<CalendarViewWorkerFilter>('active')

  const { assignment } = useAssignmentDetailContextStateValue()

  const {
    data: calendarEventsData,
    isSuccess,
    isLoading,
    isError,
  } = useCalendarEventsQuery({
    assignmentId: assignment.id,
    startDate: currentWeekStart,
    endDate: addDays(currentWeekStart, 6),
  })

  const daysOfWeek = useMemo(() => {
    return Array.from({ length: 7 }, (_, i) => addDays(currentWeekStart, i))
  }, [currentWeekStart])

  const shiftsByDate = useMemo(() => {
    const shifts = daysOfWeek.map((date) => {
      const dateString = format(date, 'yyyy-MM-dd')
      const shifts = calendarEventsData?.shifts[dateString]
      return {
        date,
        shifts,
      } as const
    })

    return shifts
  }, [calendarEventsData, currentWeekStart])

  const filteredWorkers = useMemo(() => {
    return filterWorkersByAssignmentStatus(
      calendarEventsData?.workers ?? [],
      workerFilter
    )
  }, [calendarEventsData, workerFilter])

  const value: CalendarViewContextState = {
    actions: {
      goToNextWeek: () => {
        setCurrentWeekStart(addDays(currentWeekStart, 7))
      },
      goToPreviousWeek: () => {
        setCurrentWeekStart(subDays(currentWeekStart, 7))
      },
      goToToday: () => {
        setCurrentWeekStart(startOfWeek(new Date(), { weekStartsOn: 0 }))
      },
      setWorkerFilter,
    },
    state: {
      currentWeekStart,
      daysOfWeek,
      shiftsByDate,
      workers: filteredWorkers,
      queryStatus: {
        isSuccess,
        isLoading,
        isError,
      },
      workerFilter,
    },
  }

  return (
    <CalendarViewContext.Provider value={value}>
      {children}
    </CalendarViewContext.Provider>
  )
}
