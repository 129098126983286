import React, { createContext, useContext } from 'react'
import { useAssignmentsWithRequestedCalloutsQuery } from 'queries/review/useAssignmentsWithRequestedCalloutsQuery'
import { useReviewAssignmentCalloutDecisionMutation } from 'queries/review/useReviewCalloutDecisionMutation'
import { useAuthContext } from 'components/auth'
import { ShiftWithCallouts } from '../types'

type AssignmentCalloutsContextState = {
  calloutsQueryState: {
    data: ShiftWithCallouts[]
    isLoading: boolean
    isError: boolean
  }
  calloutMutationState: {
    isPending: boolean
    isError: boolean
    reviewCalloutDecision: (
      params: Omit<
        Parameters<
          ReturnType<
            typeof useReviewAssignmentCalloutDecisionMutation
          >['mutate']
        >[0],
        'companyId'
      >
    ) => void
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export const AssignmentCalloutsContext =
  createContext<AssignmentCalloutsContextState>({
    calloutsQueryState: {
      data: [],
      isLoading: false,
      isError: false,
    },
    calloutMutationState: {
      isPending: false,
      isError: false,
      reviewCalloutDecision: noop,
    },
  })

export const useAssignmentCalloutsContextValue = () => {
  const context = useContext(AssignmentCalloutsContext)
  if (!context) {
    throw new Error(
      'useAssignmentCalloutsContextValue must be used within a AssignmentCalloutsProvider'
    )
  }
  return context
}

export const AssignmentCalloutsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { company } = useAuthContext()
  const companyId = company?.id

  const {
    data: assignmentsWithRequestedCallouts,
    isLoading,
    isError,
  } = useAssignmentsWithRequestedCalloutsQuery()
  const {
    mutate: reviewCalloutDecisionMutate,
    isPending: isReviewCalloutDecisionLoading,
    isError: isReviewCalloutDecisionError,
  } = useReviewAssignmentCalloutDecisionMutation()

  if (!companyId) {
    return null
  }

  const reviewCalloutDecision = (
    params: Omit<Parameters<typeof reviewCalloutDecisionMutate>[0], 'companyId'>
  ) => {
    reviewCalloutDecisionMutate({
      companyId: companyId,
      ...params,
    })
  }

  const value: AssignmentCalloutsContextState = {
    calloutsQueryState: {
      data: assignmentsWithRequestedCallouts || [],
      isLoading,
      isError,
    },
    calloutMutationState: {
      isPending: isReviewCalloutDecisionLoading,
      isError: isReviewCalloutDecisionError,
      reviewCalloutDecision,
    },
  }

  return (
    <AssignmentCalloutsContext.Provider value={value}>
      {children}
    </AssignmentCalloutsContext.Provider>
  )
}
