import {
  CalendarViewShift,
  CalendarViewWorker,
} from 'pages/AssignmentDetailPage/types'
import { graphql } from 'api/graphql'
import { format } from 'date-fns'
import { Assignment } from 'typings/common_defs'
import { groupBy, path } from 'ramda'
import { getShiftDate } from 'pages/AssignmentDetailPage/utils/getShiftDate'

type GetCalendarEventsRequest = {
  assignmentId: number
  startDate: Date
  endDate: Date
}

type GetCalendarEventsResponse = {
  shifts: {
    [day: string]: CalendarViewShift[]
  }
  workers: CalendarViewWorker[]
}

type GetCalendarEventsResponseDTO = {
  data: {
    company: {
      assignment: Assignment & {
        shifts: CalendarViewShift[]
        workers: {
          id: number
          name: string
          profilePicUrl: string
          workForAssignment: CalendarViewWorker['works'][string]
        }[]
      }
    }
  }
}

export const getCalendarEvents = async ({
  assignmentId,
  startDate,
  endDate,
}: GetCalendarEventsRequest): Promise<GetCalendarEventsResponse> => {
  const startDateDay = format(startDate, 'yyyyMMdd')
  const endDateDay = format(endDate, 'yyyyMMdd')

  const data = await graphql<GetCalendarEventsResponseDTO>(
    `
      company {
        assignment(assignmentId: ${assignmentId}) {
          id
          name
          workers {
            name
            id
            profilePicUrl
            workForAssignment(assignmentId:${assignmentId}, startDate: "${startDateDay}", endDate: "${endDateDay}") {
              earnings
              startedAt
              completedAt
              shift { 
                id, 
                startsAt
                endsAt
                isOrientation
                payLumpSum
                payRate
                shiftBonuses { amount }
                location {
                  address {
                    timezone
                  }
                }
              }
              status
              callouts {
                status
              }
            }
          }
          shifts(startDate: "${startDateDay}", endDate: "${endDateDay}") {
            id
            isOrientation
            startsAt
            endsAt
            location {
              id
              name
              address {
                city
                lat
                long
                state
                street
                unit
                timezone
                zip
              }
            }
            locationless
            payLumpSum
            payRate
            position {
              id
              name
            }
            shiftBonuses {
              amount
            }
          }
        }
      }
    `,
    'assignment_calendar_view'
  )

  const shifts =
    path(['data', 'data', 'company', 'assignment', 'shifts'], data) || []

  const calendarViewShifts: GetCalendarEventsResponse['shifts'] = groupBy(
    getShiftDate,
    Array.isArray(shifts) ? shifts : []
  ) as GetCalendarEventsResponse['shifts']

  const workers =
    path(['data', 'data', 'company', 'assignment', 'workers'], data) || []

  const calendarViewWorkers: GetCalendarEventsResponse['workers'] = workers.map(
    ({ workForAssignment, ...worker }) => {
      const works = groupBy(
        (work) => getShiftDate(work.shift),
        workForAssignment
      ) as GetCalendarEventsResponse['workers'][number]['works']

      return {
        ...worker,
        works,
      }
    }
  )

  const response: GetCalendarEventsResponse = {
    shifts: calendarViewShifts,
    workers: calendarViewWorkers,
  }

  return response
}
