import React, { useMemo } from 'react'
import { Select } from '@workwhile/ui'
import { useCalendarViewContextStateValue } from '../context'

const WORKER_FILTER_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Unscheduled', value: 'unscheduled' },
  { label: 'All', value: 'all' },
] as const

export const WorkerFilterSelectDropdown = () => {
  const {
    actions: { setWorkerFilter },
    state: { workerFilter },
  } = useCalendarViewContextStateValue()

  const selectedOption = useMemo(() => {
    const foundOption = WORKER_FILTER_OPTIONS.find(
      (option) => option.value === workerFilter
    )
    return {
      ...foundOption,
      label: `${foundOption?.label} workers`,
    }
  }, [workerFilter])

  return (
    <Select
      options={WORKER_FILTER_OPTIONS}
      value={selectedOption}
      aria-label="Filter workers by assignment status"
      onChange={(option) =>
        setWorkerFilter(
          (option as (typeof WORKER_FILTER_OPTIONS)[number]).value
        )
      }
    />
  )
}
