import React from 'react'
import { Box, Link, Text, useAppTheme } from '@workwhile/ui'
import { Assignment } from 'typings/common_defs'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'
import { ArrowRight } from 'lucide-react'
import { formatInTimeZone } from 'date-fns-tz'

const AssignmentDetailLinkContent = ({
  assignment,
  startDate,
  timezone,
}: {
  assignment: Assignment
  startDate: Date
  timezone: string
}) => {
  const { colors } = useAppTheme()

  const { value: shouldEnableLTA } = useGate(FeatureGates.EnableLTA)

  if (shouldEnableLTA) {
    const formattedStartDate = formatInTimeZone(
      startDate,
      timezone,
      'yyyy-MM-dd'
    )
    return (
      <>
        Part of{' '}
        <Link
          to={`/assignments/${assignment.id}?weekStart=${formattedStartDate}`}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: 4,
            fontWeight: 500,
          }}
        >
          {assignment.name} assignment
          <ArrowRight size={14} color={colors.primary200} />
        </Link>
      </>
    )
  }

  return (
    <>
      Part of{' '}
      <Text fontWeight="bold" as="b">
        {assignment.name}
      </Text>{' '}
      assignment
    </>
  )
}

type AssignmentDetailLinkProps = {
  assignment: Assignment
  startDate: Date
  timezone: string
}

export const AssignmentDetailLink = ({
  assignment,
  startDate,
  timezone,
}: AssignmentDetailLinkProps) => {
  const { radii } = useAppTheme()

  return (
    <Box
      backgroundColor="neutrals.100"
      borderBottomLeftRadius={radii.standard}
      borderBottomRightRadius={radii.standard}
      p={2}
    >
      <AssignmentDetailLinkContent
        assignment={assignment}
        startDate={startDate}
        timezone={timezone}
      />
    </Box>
  )
}
