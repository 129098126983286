import { TimelineShift } from 'api/shift'
import { formatInTimeZone } from 'date-fns-tz'
import { getShiftTimezone } from 'lib/shift'
import { LucideBriefcase, LucideCalendarClock } from 'lucide-react'
import { ShiftDetailProvider } from 'pages/ShiftDetailPage/ShiftDetailProvider'
import React from 'react'
import { Flex, Box, Paragraph } from 'ui'
import { NeedsAttentionReviewShiftRows } from './NeedsAttentionReviewShiftRows'
import {
  getAssignedWork,
  shouldShowApproveButton,
  shouldShowNeedsAttentionLabel,
} from 'lib/work'

interface Props {
  shift: TimelineShift
}

export const NeedsAttentionReviewShift = ({ shift }: Props) => {
  const timezone = getShiftTimezone(shift)
  const startDate = new Date(shift.startsAt)
  const endDate = new Date(shift.endsAt)

  const workList = shift.work || []
  const assignedWorkers = getAssignedWork(workList)
  const rowsForApproval = assignedWorkers.filter(
    (work) =>
      shouldShowApproveButton(work) && shouldShowNeedsAttentionLabel(work)
  )
  return (
    <Box>
      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'center']}
        mb={2}
      >
        <Flex flexDirection="row" alignItems="center">
          <LucideBriefcase size={16} />
          <Paragraph ml={2} mr={4}>
            {shift?.position?.name} - {shift?.location?.name}
          </Paragraph>
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          <LucideCalendarClock size={16} />
          <Paragraph ml={2} mr={4}>
            {formatInTimeZone(startDate, timezone, 'EEE, MMM d')}
            {' - '}
            {formatInTimeZone(startDate, timezone, 'h:mma')} -{' '}
            {formatInTimeZone(endDate, timezone, 'h:mma zzz')}{' '}
            {shift?.lunchLength && shift.lunchLength > 0
              ? `• Unpaid Break: ${shift?.lunchLength}mins`
              : ''}
          </Paragraph>
        </Flex>
      </Flex>
      <ShiftDetailProvider shiftId={shift.id}>
        <NeedsAttentionReviewShiftRows rowsToSkeleton={rowsForApproval} />
      </ShiftDetailProvider>
    </Box>
  )
}
