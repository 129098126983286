import React from 'react'
import { Box, Text, Timeline, useAppTheme } from '@workwhile/ui'
import { Work } from 'typings/common_defs'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'
import { format, subMinutes } from 'date-fns'
import { AddressString } from 'components/AddressString'

export function DemoTrackingInfo({ work }: { work: Work }) {
  const { shift } = useShiftDetail()
  const startsAt = shift ? new Date(shift.startsAt) : new Date()
  const earlyArrivalTime = subMinutes(startsAt, 10)
  const address = shift?.location?.address
  const { colors } = useAppTheme()

  return (
    <Box
      position="absolute"
      top={15}
      left={15}
      backgroundColor="white"
      borderRadius={10}
      boxShadow={'standard'}
      minWidth={350}
    >
      <Box py={2} px={3} borderBottom={'2px solid'} borderColor={'success'}>
        <Text>
          <strong>{work.worker?.name}</strong> arrived{' '}
          <Text
            color={'success'}
            fontWeight={'bold'}
            style={{ display: 'inline' }}
          >
            10 min
          </Text>{' '}
          early
        </Text>
      </Box>
      <Box p={3}>
        <Timeline>
          <Timeline.Item>
            <Text fontWeight={'bold'} fontSize={1}>
              Shift Started
            </Text>
            <Text fontSize={1} color={'neutrals.300'}>
              {format(startsAt, 'HH:mm EEE MMM dd')}
            </Text>
          </Timeline.Item>
          <Timeline.Item>
            {address ? (
              <Text fontWeight={'bold'} fontSize={1}>
                <AddressString address={address} />
              </Text>
            ) : null}
            <Text fontSize={1} color={'success'}>
              Arrived at {format(earlyArrivalTime, `HH:mm 'on' EEE MMM dd`)} (10
              min early)
            </Text>
          </Timeline.Item>
          <Timeline.Item color={colors.success}>
            {address ? (
              <Text fontWeight={'bold'} fontSize={1}>
                Started Work
              </Text>
            ) : null}
            <Text fontSize={1} color={'neutrals.300'}>
              {format(startsAt, 'HH:mm EEE MMM dd')}
            </Text>
          </Timeline.Item>
        </Timeline>
      </Box>
    </Box>
  )
}
