import React from 'react'
import { Box, Flex, Heading, Label, Loading, Message, Text } from 'ui'
import { useAssignmentCalloutsContextValue } from './context/AssignmentCalloutsProvider'
import { PendingCalloutReviews } from './components/PendingCalloutReviews'

export const AssignmentCalloutsContainer = () => {
  const {
    calloutsQueryState: { isLoading, isError, data },
  } = useAssignmentCalloutsContextValue()

  // We don't want to show the callouts at all if there are no shifts with callouts
  // At some point in future (after v0 launch), we'll redesign this to show a safe empty state
  if (isLoading || data.length === 0) {
    return null
  }

  return (
    <Box mb={4}>
      <Flex alignItems="center">
        <Heading level={2} mb={0} mr={2} fontWeight="regular">
          Callouts
        </Heading>
        <Label variant="error" size="medium">
          Needs attention
        </Label>
      </Flex>
      <Text color="lightText" mt={-2} mb={2}>
        Callout requests for assignments submitted by your workers show up here.
        They'll be notified of your decision via SMS.
      </Text>
      {isLoading ? <Loading /> : null}
      {isError ? (
        <Message variant="error">
          Hmm... we're having trouble loading assignment callouts. If you
          require assistance, please copy the URL in your browser's search bar
          and send it to WorkWhile Support.
        </Message>
      ) : null}
      {!isLoading && !isError && data.length > 0 ? (
        <PendingCalloutReviews />
      ) : null}
    </Box>
  )
}
