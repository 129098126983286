import React from 'react'
import { CompanyLog, LogEventType } from 'api/company'
import {
  BriefcaseIcon,
  CircleDollarSign,
  ClockIcon,
  FileEditIcon,
  InfoIcon,
  PartyPopperIcon,
  PlusCircleIcon,
  StarIcon,
  UserSquareIcon,
  CalendarMinus2Icon,
  CalendarCheckIcon,
  CalendarXIcon,
} from 'lucide-react'

export function LogItemIcon(props: { data: CompanyLog }) {
  const { data } = props

  switch (data.eventType) {
    case LogEventType.ShiftCreate:
      return <PlusCircleIcon size={24} />
    case LogEventType.ShiftModify:
      return <FileEditIcon size={24} />
    case LogEventType.E2WReview:
      return <StarIcon size={24} />
    case LogEventType.Work:
      if (data.message.includes('started')) {
        return <ClockIcon size={24} />
      }
      if (data.message.includes('completed')) {
        return <PartyPopperIcon size={24} />
      }
      return <BriefcaseIcon size={24} />
    case LogEventType.CompanyWorkerProfile:
      return <UserSquareIcon size={24} />
    case LogEventType.UserPayment:
      return <CircleDollarSign size={24} />
    case LogEventType.RequestCallout:
      return <CalendarMinus2Icon size={24} />
    case LogEventType.ApproveCallout:
      return <CalendarCheckIcon size={24} />
    case LogEventType.RejectCallout:
      return <CalendarXIcon size={24} />
    default:
      return <InfoIcon size={24} />
  }
}
