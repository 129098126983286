import { useMutation, useQueryClient } from '@tanstack/react-query'
import { reviewAssignmentCalloutDecision } from 'api/review/reviewAssignmentCalloutDecision'
import { reviewAssignmentsWithRequestedCalloutsKey } from './keys'

export const useReviewAssignmentCalloutDecisionMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: reviewAssignmentCalloutDecision,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reviewAssignmentsWithRequestedCalloutsKey,
      })
    },
  })
}
