import { Page } from 'pages/layout'
import React from 'react'
import { NeedsAttention } from './NeedsAttention'
import { Evaluate } from './Evaluate'
import { Tryouts } from './Tryouts'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'
import { AssignmentCallouts } from './AssignmentCallouts'

export function ReviewPage() {
  const { value: shouldEnableLTA } = useGate(FeatureGates.EnableLTA)
  return (
    <Page title="Review">
      {shouldEnableLTA ? <AssignmentCallouts /> : null}
      <Tryouts />
      <NeedsAttention />
      <Evaluate />
    </Page>
  )
}
